<template>
    <div class="sticky top-0 flex h-16 flex-shrink-0 bg-white border-b border-gray-200 z-10 px-8">
        <button
            type="button"
            class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sendback-500 md:hidden"
            @click="$bus.emit('sidebar.open')">
            <span class="sr-only">{{ t("Open sidebar") }}</span>
            <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex flex-1 justify-between px-4 md:px-0">
            <div class="flex flex-1 max-w-full">
                <LayoutHeaderSearch></LayoutHeaderSearch>
            </div>
            <div class="ml-4 flex items-center md:ml-6">
                <button
                    type="button"
                    class="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sendback-500 focus:ring-offset-2">
                    <span class="sr-only">{{ t("View notifications") }}</span>
                    <BellIcon class="h-6 w-6" aria-hidden="true" />
                </button>

                <!-- Profile dropdown -->
                <Menu as="div" class="relative ml-3">
                    <div>
                        <MenuButton class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-sendback-50">
                            <span class="sr-only">{{ t("Open user menu") }}</span>
                            <span class="text-lg font-medium leading-none text-sendback-400" v-text="user?.name[0]"></span>
                        </MenuButton>
                    </div>
                    <transition
                        enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95">
                        <MenuItems
                            class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                                <NuxtLink
                                    :to="item.href"
                                    class="transition-all"
                                    :class="[active ? 'bg-sendback-50/60' : '', 'block py-2 px-4 text-sm text-gray-700']"
                                    >{{ t(item.name) }}</NuxtLink
                                >
                            </MenuItem>
                            <MenuItem v-if="user.impersonated">
                                <button @click="leaveImpersonation" class="block py-2 px-4 text-sm text-gray-700">Torna admin</button>
                            </MenuItem>
                            <MenuItem v-else>
                                <button @click="logout" class="block text-left w-full py-2 px-4 text-sm hover:bg-sendback-50/60 text-highlight transition-all">
                                    {{ t("Sign out") }}
                                </button>
                            </MenuItem>
                        </MenuItems>
                    </transition>
                </Menu>

                <button v-for="locale in availableLocales" :key="locale.code" @click="$i18n.setLocale(locale.code)">
                    <span :title="locale.name" :class="['ml-3 fi', 'fi-' + (locale.code == 'en' ? 'gb' : locale.code)]"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import "/node_modules/flag-icons/css/flag-icons.min.css";

    import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

    import { Bars3BottomLeftIcon, BellIcon } from "@heroicons/vue/24/outline";

    const user = useUser();
    const { logout, leaveImpersonation } = useAuth();
    const { locale, locales, t } = useI18n();

    const userNavigation = [
        { name: "Your organization", href: "/organization" },
        { name: "Settings", href: "/settings" },
    ];

    const availableLocales = computed(() => {
        return locales.value.filter((i) => i.code !== locale.value);
    });
</script>
