<template>
    <div>
        <LayoutAppMenu></LayoutAppMenu>
        <div class="md:pl-64">
            <div class="mx-auto flex max-w-9xl flex-col xxl:px-0 bg-gray-50">
                <LayoutAppHeader></LayoutAppHeader>

                <main class="flex-1">
                    <slot />
                </main>
            </div>
        </div>
        <LayoutAppFooter></LayoutAppFooter>
        <Notifications></Notifications>
    </div>
</template>
